	<template>
		<v-container class="mt-8">
		<v-parallax v-if="$vuetify.breakpoint.mdAndUp"
			:src="require('../assets/avatar_pic/48189_cut.jpeg')"
			height="320"
			class="parallax-banner"
			style="border-radius: 8px;"
		>
		</v-parallax>
		<v-img v-else style="border-radius: 8px;" src="../assets/avatar_pic/48189_cut.jpeg" alt="Blend Dehari" max-width="100%"></v-img>

		<v-container class="mt-8">
			<v-card class="elevation-12">
			<v-card-title class="headline">What I'm Working On Right Now</v-card-title>
			<v-card-text>
				<ul>
				<li>Developing and maintaining <a href="https://www.alturos.com/myservices/" target="_blank" class="no-decoration">MyServices at Alturos Destinations<v-icon size="small">mdi-open-in-new</v-icon></a></li>
				<li>Staying curious about different tech domains and embracing new challenges</li>
				<li>Exploring cutting-edge technologies and best practices</li>
				</ul>
			</v-card-text>
			</v-card>

			<v-card class="elevation-12 mt-8">
			<v-card-title class="headline">My Background</v-card-title>
			<v-card-text>
				<ul>
				<li>I hold a Master's degree in Computer Science from <a href="https://www.aau.at/" target="_blank" class="no-decoration">Universität Klagenfurt</a>. I completed my Master's Thesis in <a href="https://www.bbmri-eric.eu/" target="_blank" class="no-decoration">Biomedical Technology</a> <v-icon size="small">mdi-open-in-new</v-icon></li>
				<ul>
					<li><a href="https://netlibrary.aau.at/obvuklhs/content/titleinfo/8358675" target="_blank" class="no-decoration">Thesis Metadata <v-icon size="small">mdi-open-in-new</v-icon></a></li>
					<li><a href="https://netlibrary.aau.at/obvuklhs/download/pdf/8358675?originalFilename=true" class="no-decoration">Full Thesis PDF <v-icon size="small">mdi-open-in-new</v-icon></a></li>
					<v-dialog transition="dialog-bottom-transition" max-width="600">
					<template v-slot:activator="{ on, attrs }">
						<li v-bind="attrs" class="no-decoration" v-on="on">Thesis abstract</li>
					</template>
					<template v-slot:default="dialog">
						<v-card>
						<v-toolbar color="primary" dark>Central Anonymized Index Structure for Searching in Federated Biobanks</v-toolbar>
						<v-card-text>
							<div class="text pa-12">
							Medical research requires the collection of biological samples and data using dependable
								procedures and quality control from biobanks. Recent examples of withdrawn studies show that
								medical studies based on data of unknown or dubious quality are useless or even harmful. Medical
								data sets contain extremely sensitive personal information that must be carefully protected and are
								only made accessible for research with the approval of ethics committees. Medical researchers
								require this information to carry out their studies as well as to find cases of data that are pertinent
								to their research.
								It is currently very difficult to find biobanks that have the necessary data, and the process of
								obtaining it is very tedious. Thus, both an infrastructure for searching and locating the data as well
								as the capability for interpreting it is required. The degree of data heterogeneity is a major issue
								when assembling data from several biobanks. To address this, we characterize each collection in
								terms of an ontological concept rather than the set of attributes we discover in the associated data.
								As a result, we annotate all the data that is obtainable for a collection using a reference ontology
								and a set of ontological concepts.
								The aim of this study is to suggest an architecture that will assist researchers in quickly and
								accurately (with a certain confidence) locating relevant collections of information and data with
								established quality for their research projects while strictly adhering to privacy laws. This thesis
								focuses on the first point as part of a larger joint research and development effort that aims at
								tackling this problem. The collective work makes it possible to centrally store biobank data
								annotated through a reference ontology in an anonymized form.
							</div>
							<div class="text pa-12">
							Supervisors: <br/>
							<a href="https://www.aau.at/team/eder-johann/" target="_blank" class="no-decoration">O.Univ.-Prof. Dipl.-Ing. Dr. Johann Eder <v-icon size="small">mdi-open-in-new</v-icon></a> <br/>
							<a href="https://www.aau.at/team/shekhovtsov-volodymyr/" target="_blank" class="no-decoration">Dr.phil. Volodymyr Shekhovtsov <v-icon size="small">mdi-open-in-new</v-icon></a>
							</div>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn text @click="dialog.value = false">Close</v-btn>
						</v-card-actions>
						</v-card>
					</template>
					</v-dialog>
				</ul>
				<li>The work of my thesis was featured in the <a href="https://www.europebiobankweek.eu/about/" target="_blank" class="no-decoration">Europe Biobank Week (EBW) 24</a> conference by my mentors:
					<a href="https://www.aau.at/team/eder-johann/" target="_blank" class="no-decoration">O.Univ.-Prof. Dipl.-Ing. Dr. Johann Eder</a> and 
					<a href="https://www.aau.at/team/shekhovtsov-volodymyr/" target="_blank" class="no-decoration">Dr.phil. Volodymyr Shekhovtsov <v-icon size="small">mdi-open-in-new</v-icon></a>
					<v-dialog transition="dialog-bottom-transition" max-width="600">
					<template v-slot:activator="{ on, attrs }">
						<li v-bind="attrs" class="no-decoration" v-on="on">Abstract of the presentation</li>
					</template>
					<template v-slot:default="dialog">
						<v-card>
						<v-toolbar color="primary" dark>Sample Index:A tool for searching in biobank data taking into account privacy restrictions</v-toolbar>
						<v-card-text>
							<div class="text pa-6"><b>Introduction</b>: To provide material and data to researchers efficiently, biobanks have to implement means of searching for collections that contain the data needed for research. The problem with such a search is that direct access to the sample data may be subject to privacy restrictions. One solution is to search in a central repository of quality annotated metadata augmented with indexes.</div>
						</v-card-text>
						<v-card-text>
							<div class="text pa-6">
								<b>Methods</b>: As part of BBMRI.at research, in addition to metadata-based search, we propose to design and implement a sample index tool supporting the search for suitable collections within preprocessed non-sensitive data. It consists of the data anonymization component converting the biobank data into a non-sensitive form, the repository storage component holding metadata, quality data, and index data, and the search component running queries against the repository and returning requested collections.
							</div>
						</v-card-text>
							<v-card-text>
								<div class="text pa-6">
								<b>Results</b>: The concept of a sample index was implemented as a software tool that supports a search within the repository storing biobank data after its K-anonymization, aggregation, and annotation with OMOP CDM concepts. It was validated with data from the BBMRI Colorectal Cancer Cohort.
								</div>
						</v-card-text>
						<v-card-text>
								<div class="text pa-6">
								<b>Discussion</b>: We address compliance with the accessibility principle for biobank data by offering the possibility to search within non-sensitive data when the original data is not accessible. The novelty of the tool lies in its support for semantic data annotation together with its aggregation and anonymization such that the central repository can offer rather fine grain information about collections and their associated data sets without any potential compromise of the privacy of the donors.
								</div>
								<div class="text pa-6">
								<b>Authors</b>: Shekhovtsov, V.A., Göbel, G., Eder, J., Dehari, B., Eisenkeil, P. 
								</div>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn text @click="dialog.value = false">Close</v-btn>
						</v-card-actions>
						</v-card>
					</template>
					</v-dialog>
				</li>
				<li>Over 3 years of experience as a Software Engineer in Tourism Tech at <a href="https://www.alturos.com/en/" target="_blank" class="no-decoration">Alturos Destinations</a> <v-icon size="small">mdi-open-in-new</v-icon></li>
				<li>Passionate about science, technology, and continuous learning</li>
				</ul>
			</v-card-text>
			</v-card>

			<v-card class="elevation-12 my-8">
			<v-card-title class="headline">Get in Touch</v-card-title>
			<v-card-text>
				<p>Feel free to <a href="mailto:blenddehari@gmail.com" class="no-decoration">email me</a> or connect with me on social media:</p>
				<v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
				<v-icon size="24px" @click="redirect(icon)">
					{{ icon }}
				</v-icon>
				</v-btn>
				<div class="mt-4">
				<p>The next time you find yourself in Düsseldorf or if I happen to be exploring your town (keep tabs on my social channels), let's grab a coffee! ☕😄</p>
				</div>
			</v-card-text>
			</v-card>
		</v-container>
	</v-container>
</template>

<script>
	export default {
	name: "AboutMe",
	head() {
	return {
		meta: [
		{ property: "og:title", content: "About Me Page - Blend Dehari's background" },
		{ property: "og:description", content: "About Me Page - Blend Dehari's background" },
		{ property: "og:image", content: "../assets/avatar_pic/48189_cut.jpeg" },
		{ property: "og:url", content: "https://blenddehari.com" },
		{ property: "og:type", content: "Personal Technical Blog" },
		],
	};
	},
	data: () => ({
	icons: [
		'mdi-facebook',
		'mdi-linkedin',
		'mdi-instagram',
	],
	}),
	methods: {
	redirect(icon) {
		let url = '';
		switch (icon) {
		case 'mdi-facebook':
			url = 'https://www.facebook.com/your-profile';
			break;
		case 'mdi-linkedin':
			url = 'https://www.linkedin.com/in/your-profile';
			break;
		case 'mdi-instagram':
			url = 'https://www.instagram.com/your-profile';
			break;
		}
		window.open(url, '_blank');
	},
	},
	};
</script>

<style scoped>
	.elevation-12 {
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	padding: 16px;
	}

	.headline {
	font-size: 24px;
	font-weight: bold;
	}

	.v-card {
	margin-bottom: 32px;
	transition: transform 0.3s ease;
	}

	.v-card:hover {
	transform: scale(1.02);
	}

	.v-card:last-child {
	margin-bottom: 0;
	}

	.v-card-text {
	font-size: 18px;
	}

	.v-card-text ul {
	margin: 0;
	padding: 0;
	list-style: none;
	}

	.v-card-text ul li {
	margin-bottom: 8px;
	}

	.v-card-text ul li:last-child {
	margin-bottom: 0;
	}

	.v-card-text a:active {
	color: #2196f3;
	}

	.display-2 {
	font-size: 48px;
	font-weight: bold;
	margin-top: 2rem;
	}

	.v-parallax {
	margin-bottom: 2rem;
	}

	.no-decoration {
	text-decoration: none;
	color: #2196f3;
	}

	.no-decoration:hover {
	font-size: 1.1rem;
	}

	@media (max-width: 600px) {
	.display-2 {
	font-size: 32px;
	}
	.headline {
	font-size: 20px;
	}
	
	}
</style>
